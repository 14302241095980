import React from "react";
import "./modal.css";
import FaceRecognition from "../FaceRecognition/FaceRecognition";

const Modal = ({ closeModal, modalState, title, age, imageUrl }) => {
  if (!modalState) {
    return null;
  }

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title has-text-weight-semibold">{title}</p>
          <button className="delete" onClick={closeModal} />
        </header>
        <section className="modal-card-body">
          <div className="content">
            <FaceRecognition imageUrl={imageUrl} />
            <table className="table is-bordered is-striped is-narrow is-hoverable">
              <thead>
                <tr>
                  <th>
                    {" "}
                    Ethnicity{" "}
                    <span className="icon is-primary">
                      <i className="fas fa-flag"></i>
                    </span>
                  </th>
                  <th>Likelihood</th>
                </tr>
              </thead>
              <tbody>
                {age.map((event, index) => {
                  return (
                    <tr key={index}>
                      <td>{event.name}</td>
                      <td key={index}>{Math.round(event.value * 100)}%</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-primary" onClick={closeModal}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};

export default Modal;
