import React from "react";
import Modal from "../Modal/modal";

const displayData = (
  age,
  gender,
  type,
  closeModal,
  concepts,
  modalState,
  imageUrl
) => {
  if (age) {
    return (
      <section className="section">
        <div className="container">
          <Modal
            closeModal={closeModal}
            modalState={modalState}
            title="Report"
            age={age}
            gender={gender}
            type={type}
            imageUrl={imageUrl}
            concepts={concepts}
          >
            {" "}
          </Modal>
        </div>
      </section>
    );
  }
};

const Rank = ({
  age,
  gender,
  type,
  concepts,
  closeModal,
  modalState,
  imageUrl,
}) => {
  return (
    <React.Fragment>
      {displayData(
        age,
        gender,
        type,
        concepts,
        closeModal,
        modalState,
        imageUrl
      )}
    </React.Fragment>
  );
};

export default Rank;
