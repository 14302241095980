import React, { Component } from "react";
import "./App.css";
//import Navigation from "./components/navigation/navigation";
import ImageLinkForm from "./components/imageLinkForm/imageLinkForm";
import Rank from "./components/rank/rank";
import Clarifai from "clarifai";
//clarifai api key
const app = new Clarifai.App({
  apiKey: "0a9858bc9d9844bf9585b670c2866098",
});

class App extends Component {
  state = {
    input: "",
    imageUrl: "",
    age: "",
    gender: "",
    type: "",
    concepts: "",
    modalState: false,
  };

  toggleModal = () => {
    this.setState((prev, props) => {
      const newState = !prev.modalState;

      return { modalState: newState };
    });
  };

  onInputChange = (event) => {
    this.setState({ input: event.target.value });
  };

  onSubmit = () => {
    this.setState({ imageUrl: this.state.input });
    app.models
      .predict("93c277ec3940fba661491fda4d3ccfa0", this.state.input)
      .then(
        (response) => {
          // const api_data = response.outputs[0].data.regions[0].data.face;

          const concepts = response.outputs[0].data.concepts;

         

          this.setState({ age: concepts });

          // this.setState({ age: api_data.age_appearance.concepts[0].name });
          // this.setState({
          //   gender: api_data.gender_appearance.concepts[0].name,
          // });
          // this.setState({
          //   type: api_data.multicultural_appearance.concepts[0].name,
          // });
        },
        function (err) {
          console.log("Err", err);
          // there was an error
        }
      )
      .then(() => {
        this.setState({ input: "" });
      })
      .catch((err) => {
        console.log("Err", err);
      });
  };
  render() {
    return (
      <div className="App">
        <ImageLinkForm
          onSubmit={this.onSubmit}
          onInputChange={this.onInputChange}
          closeModal={this.toggleModal}
          inputValue={this.state.input}
        />
        <Rank
          age={this.state.age}
          gender={this.state.gender}
          type={this.state.type}
          closeModal={this.toggleModal}
          modalState={this.state.modalState}
          imageUrl={this.state.imageUrl}
          concepts={this.state.concepts}
        />
      </div>
    );
  }
}

export default App;
